import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import VueI18n from "@/libs/i18n";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useProductsList() {
  // Use toast
  const toast = useToast();

  const refProductListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "kod", label: VueI18n.t("code"), sortable: true },
    {
      key: "ingredientname",
      label: VueI18n.t("productName"),
      sortable: true,
    },
    // {
    //   key: "product",
    //   label: VueI18n.t("isProduct"),
    //   formatter: title,
    //   sortable: true,
    // },
    // { key: "cinsname", label: VueI18n.t("type"), sortable: false },
    { key: "delete", label: VueI18n.t("delete"), sortable: false },
    { key: "edit", label: VueI18n.t("edit"), sortable: false },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refProductListTable.value
      ? refProductListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refProductListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );
  const fetchProducts = async (ctx, callback) => {
    var companyProduct = await store
      .dispatch(
        "companyProductsModule/fetchCompanyProducts",
        store.state.app.selectedPlantId
      )

      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("xFetchError", {
              value: VueI18n.t("companyProducts"),
            }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    return companyProduct;
  };

  const deleteProduct = (ctx) => {
    store
      .dispatch("companyProductsModule/deleteCompanyProduct", ctx)
      .then((response) => {
        refetchData();
        response
          ? toast({
              component: ToastificationContent,
              props: {
                title: VueI18n.t("deleted", {
                  type: VueI18n.t("companyProducts"),
                }),
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          : toast({
              component: ToastificationContent,
              props: {
                title: VueI18n.t("notDeleted", {
                  type: VueI18n.t("companyProducts"),
                }),
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("notDeleted", {
              type: VueI18n.t("companyProducts"),
            }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchProducts,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProductListTable,
    deleteProduct,

    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
